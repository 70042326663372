import * as React from 'react';
import {
  generateSubmissionPortalPreviewRoute,
  generateSubmissionPreviewRoute,
  PORTAL_PREVIEW_FLAG,
} from '../../../components/App/Content/Preview/PreviewRoute';
import { StyledButton } from '../../Styleguide/Common/Button';
import { Box } from '@mui/material';
import { StyledBodyText, StyledHeaderTextH2 } from '../../Styleguide/Common/Text';
import { Link } from 'react-router-dom';
import { Submission } from '../../../components/App/Content/UserDeveloper/Submission/submission-graphql';
import { GameJamLink, getGameJamLink } from '../../../components/App/Content/UserDeveloper/Submission/gamejamHelper';

export interface PreviewProps {
  submission: Submission;
}

type Props = PreviewProps;

const Preview: React.FC<Props> = ({ submission }) => {
  if (submission.isGameJam) {
    const link = getGameJamLink(submission.id);
    return (
      <Box sx={{ ml: 2.5, pt: 2 }}>
        <StyledHeaderTextH2 sx={{ mt: 1, mb: 0 }}>Here is your Game Jam submission link</StyledHeaderTextH2>
        <GameJamLink href={link}>{link}</GameJamLink>
      </Box>
    );
  }

  const portalLink = generateSubmissionPortalPreviewRoute(submission.id);
  const link = generateSubmissionPreviewRoute(submission.id);
  return (
    <Box sx={{ ml: 2.5, pt: 2 }}>
      <StyledHeaderTextH2 sx={{ mt: 1, mb: 0 }}>Preview your game</StyledHeaderTextH2>
      <StyledBodyText sx={{ mt: 1, mb: 2 }} color="white60">
        You can preview the game by clicking on the button below. Note that the preview tries to match how the game will be when it goes
        live on crazygames.com, however small differences may occur.
      </StyledBodyText>
      {PORTAL_PREVIEW_FLAG && (
        <StyledButton variant="contained" onClick={() => (window.location.href = portalLink)}>
          Preview
        </StyledButton>
      )}
      {!PORTAL_PREVIEW_FLAG && (
        <Link to={link.href}>
          <StyledButton variant="contained">Preview</StyledButton>
        </Link>
      )}
    </Box>
  );
};

export default Preview;
